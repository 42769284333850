






import { Component, Vue } from "vue-property-decorator"

@Component({
	components: {},
})
export default class Home extends Vue {
	mounted() {
		const fbScript = document.createElement("script")
		fbScript.setAttribute("src", "https://apps.elfsight.com/p/platform.js")
		fbScript.setAttribute("defer", "")
		document.head.appendChild(fbScript)
	}
}
