







































































export default {
	data() {
		return {
			form: {
				name: "",
				phone: "",
				email: "",
				message: "",
			},
		}
	},
}
