import { render, staticRenderFns } from "./Batteries.vue?vue&type=template&id=73dc96a4&scoped=true&"
var script = {}
import style0 from "./Batteries.vue?vue&type=style&index=0&id=73dc96a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73dc96a4",
  null
  
)

export default component.exports